.sign-up-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  align-items: center;
  text-align: left;

}

.signup-errors{
  color: red;
  font-weight: bold;
}

.sign-up-modal {
  height: 95vh;
  align-items: center;
  text-align: center;
  overflow: hidden;

}

.sign-up-child {
  padding: 5px;
  align-self: flex-start;
  width: 100%;
  margin-left: 20px;
}

.sign-up-child input,
label,
textarea,
button {
  display: block;
  font-size: 16px;
  width: 95%;
  margin: 0;
}

.sign-up-child label {
    font-weight: bold;
}


.galaxy {
  width: 25%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 15px;
}

.galaxy img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

.sign-up-child button {
  background-image: url("../../images/galaxy-background.jpg");
  object-fit: fill;
  color: white;
  width: 75%;
  border: 2px solid white;
  border-radius: 5px;
  height: 70px;
  margin: auto;
  font-weight: bolder;
}

.sign-up-child button:hover {
    background: none;
    background-color: white;
    color: #010C80;
    border: 3px solid #010C80;
}

.password-container,
.name-container,
.location-container {
  display: flex;
  flex-wrap: wrap;

}

.password-container label,
.name-container label,
.location-container  label {
  width: 46.5%; 
}