.main-page-container {
    background-image: url("../../images/galaxy-background.jpg");
    background-size: auto;
    width: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    
}
