.user-bio img{
    width: 100%;
}

.user-bio {
    background-color: white;
    border-radius: 15px;
    width: 25%;
    height:min-content;
    padding: 1%;
    margin: 3%;
}

.no-user-post {
    background-color: white;
    border-radius: 15px;
    font-size: 40px;
    align-self: center;
    width: max-content;
    padding: 5%;
    margin: 50%;
}


.profile-page-container {
    background-image: url("../../images/galaxy-background.jpg");
    background-size: cover;
    min-height: 100vh;
    display: flex;
    
}

.user-bio p {
    padding: 0.5rem;
    margin-top: 1rem;
    border: 1px solid #010C80;
    border-radius: 5px;
}
