.post-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 2%;
  background-color: white;
  border-radius: 15px;
  width: 60rem;
}

.user-post-info {
  display: grid;
  grid-template-areas:
  "user post post post";
  grid-template-columns: 10rem 40rem;
}

.user-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    grid-area: user;
}
.user-info img {
  width: 5vw;
}

.post-info img {
  width: 50%;
  margin-bottom: 2%;
}

.post-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-area: post;
}

.likes-comments {
  display: flex;
  justify-content: flex-start;
}
.comment-content {
  width: 100%;
}

.comment-section {
  display: flex;
  justify-content: center;
}

.comment-section p {
  margin-left: 5px;
}

.delete-update-post {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.delete-post button {
  background-color: red;
  color: white;
  font-weight: bolder;
  border: 2px solid white;
  border-radius: 5px;
  width: 75px;
}

.delete-post button:hover {
  background-color: white;
  color: red;
  border: 2px solid red;
}

.update-post button {
  background-color: #010c80;
  color: white;
  font-weight: bolder;
  border: 2px solid white;
  border-radius: 5px;
  width: 75px;
}

.update-post button:hover {
  background-color: white;
  color: #010c80;
  border: 2px solid #010c80;
}
