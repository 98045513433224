.search-image img {
    width: 4rem;
    margin-right: 10px;
}

.response-list-item{
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    padding: 0.5rem;
}

.search-image, .name-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.result-list {
    background-color: white;
    position: absolute;
    z-index: 1;
    background-color: #f1f1f1;
    padding: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
