.delete-account-button button {
    background-color: red;
    color: white;
    border: none;
}

.delete-account-button button:hover {
    background-color: white;
    color: red;
    border: 3px solid red;
}

.cancel-account-button button {
    background-color: green;
    color: white;
    border: none;
}

.cancel-account-button button:hover {
    background-color: white;
    color: green;
    border: 3px solid green;
}

.delete-account-modal {
    margin: 10%;
}

.delete-account-modal p {
    margin-bottom: 7%;
}
