.post-content {
    display: flex;
    align-items: center;
}

.create-post {
    background-color: white;
    border-radius: 5px;
}

.post-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.post-content img {
    width: 5%;
}

.post-content {
    margin-top: 1rem;
    border: 2px solid #010C80;
    border-radius: 5px;
}

.news-feed {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
